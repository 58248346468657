<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
        <button
          class="btn btn-primary btn-margin"
          @click="privateMessage()">
          Call Private !!!!ppp
        </button>
          {{ message }}
  </div>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'
const API_URL = 'https://api.maniacmansion.cloud'
export default {
  name: 'RGBHome',
  data () {
    return {
      message: ''
    }
  },
  methods: {
    privateMessage2 () {
      const url = `${API_URL}/`
      return axios.get(url, {headers: {Authorization: `Bearer`}}).then((response) => {
        this.message = response.data || ''
      })
    },
    privateMessage () {
      const url = `${API_URL}/`
      Auth.currentAuthenticatedUser().then(user => { 
        axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken;    
      })

      return axios.get(url).then((response) => {
        this.message = response.data || ''
      })
    }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
